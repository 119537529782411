





































import {
  Component, Vue,
} from 'vue-property-decorator';
import LinkStatusTransferScheduledViewModel
  from '@/vue-app/view-models/components/strategy-moderate-wealth/link-status-moderate/link-status-transfer-scheduled-view-model';

@Component({ name: 'LinkStatusTransferScheduled' })
export default class LinkStatusTransferScheduled extends Vue {
  link_status_transfer_scheduled = Vue.observable(
    new LinkStatusTransferScheduledViewModel(this),
  );
}
